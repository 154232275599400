<template>
  <div>
    <select v-model="inputValue" class="form-control mt-1" :disabled="disabled">
      <option
        v-for="(user, index) in advisors"
        :key="index"
        :value="user.id"
        :selected="user.id == inputValue"
      >
        {{ user.firstName }} {{ user.lastName }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: "CompanyAdvisor",
  data() {
    return {};
  },
  props: {
    value: String,
    disabled: Boolean,
  },
  computed: {
    advisors() {
      return this.$store.getters["user/advisors"];
    },
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss">
</style>