<template>
    <section class="container">
        <b-card title="Uafhængig rådgiver" class="advisor-card">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'Company', 'CompanyAdvisors')">
                        <font-awesome-icon :icon="['far', 'edit']" class="icon" />
                    </BaseActionButton>
                </div>
            </div>

            <div class="row">
                <b-form @submit.prevent="onSubmit" class="col-12">
                    <!--Info-->
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-12">
                            <label> {{ $t('global.primaryAdvisor') }}</label>
                            <company-advisor v-model="company.companyAdvisors.primaryAdvisor" :disabled="disabled" />
                            <label> {{ $t('global.secondaryAdvisor') }}</label>
                            <company-advisor v-model="company.companyAdvisors.secondaryAdvisor" :disabled="disabled" />
                        </div>
                    </div>

                    <br />

                    <div class="row">
                        <div class="col-12">
                            <h3>Notifikation indstillinger</h3>

                            <div class="row justify-content-center">
                                <br /><br />
                                Brugere som skal modtage notifikation
                                <Multiselect
                                    v-model="company.notificationSubscriptions"
                                    :options="advisors.map((user) => user.id)"
                                    :custom-label="(opt) => advisors.find((x) => x.id == opt).fullName"
                                    :multiple="true"
                                    taggable
                                    :disabled="disabled"
									class="font-size: 1.5rem;"
                                ></Multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-5">
                            <BaseActionButton
                                class="btn-update"
                                v-bind:clicked="clicked"
                                v-bind:disabled="disabled || clicked"
                                type="submit"
                                v-if="$can('update', 'Company', 'CompanyAdvisors')"
                            >
                                {{ $t('global.update') }}
                            </BaseActionButton>
                        </div>
                    </div>
                </b-form>
            </div>
        </b-card>
    </section>
</template>

<script>
import CompanyAdvisor from '@/modules/global/components/AdvisorDropdown.vue'
import Multiselect from 'vue-multiselect'

export default {
    name: 'CompanyEdit',
    components: {
        CompanyAdvisor,
        Multiselect,
    },
    async mounted() {
        this.$parent.$emit('updateLoader')
    },
    data() {
        return {
            disabled: true,
            clicked: false,
        }
    },
    computed: {
        id() {
            return this.$route.params.companyId
        },
        company() {
            return this.$store.getters['companyVuex/company']
        },
        advisors() {
            return this.$store.getters['user/advisors']
        },
    },
    methods: {
        async onSubmit() {
            this.clicked = true
            var data = this.company
            var id = this.id
            try {
                var result = await this.$store.dispatch('companyVuex/updateCompany', {
                    data,
                    id,
                })
                this.clicked = false
                this.toast('Success', 'Et selskab nu opdateret', true)
            } catch (error) {
                this.clicked = false
                this.toast(error.response.data.code, error.response.data.message, false)
            }
        },
        setEditable() {
            this.disabled = !this.disabled
        },
    },
}
</script>

<style lang="scss">
@import '@/styles/pages/companyAdd.scss';

.advisor-card {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon {
  margin-right: 8px;
}

label {
  font-weight: 600;
  margin-bottom: 5px;
}

h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 700;
}

.btn-update {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-update:hover {
  background-color: #0056b3;
}

.multiselect {
  width: 100%;
}

.multiselect__tag {
  background-color: #007bff;
  color: #ffffff;
}

.multiselect__tag-icon {
  color: #ffffff;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>